import gql from 'graphql-tag';

export const CAMPAIGN_DRAFT = gql`mutation($campaignDraft: CampaignDraft!, $draftId: String) {
                                            campaignDraft(campaignDraft: $campaignDraft, draftId: $draftId) {
                                              successMessage,
                                              data
                                            }
                                      }`;

export const CREATE_CAMPAIGN = gql`mutation($orgId: Float!, $campaignRequest: CampaignDraft!) {
                                            createCampaign(orgId: $orgId, campaignRequest: $campaignRequest) {
                                              successMessage,
                                              data
                                              searchAdsErrors
                                            }
                                      }`


export const DELETE_INTEGRATION = gql`mutation($identifier: String!, $type: String!){
                                        deleteIntegration(identifier: $identifier, type: $type){
                                          successMessage,
                                          data
                                        }
                                      }`

export const UPDATE_CAMPAIGN = gql`mutation($orgId: Float!, $campaignId: Float!, $campaignRequest: CampaignUpdateReq!) {
                                  updateCampaign(orgId: $orgId, campaignId: $campaignId, campaignRequest: $campaignRequest) {
                                      successMessage,
                                      data
                                      searchAdsErrors
                                    }
                                  }`

export const TOGGLE_ADGROUP = gql`mutation($orgId: Float!, $campaignId: Float!, $adgroupId: Float!, $adGroupStatus: AdGroupStatusChange!) {
                                  enableDisableAdGroup(orgId: $orgId, campaignId: $campaignId, adgroupId: $adgroupId, adGroupStatus: $adGroupStatus) {
                                    successMessage,
                                    data
                                  }
                                }`

export const TOGGLE_CREATIVE_SET = gql`mutation($orgId: Float!, $enablePauseCreativeSetReq: ChangeCreativeSetStatusReq!) {
  enablePauseCreativeSet(orgId: $orgId, enablePauseCreativeSetReq: $enablePauseCreativeSetReq) {
                                      successMessage,
                                      data
                                    }
                                  }`

export const ADD_KEYWORDS = gql`mutation($addKeywords: AddKeywordsReq!) {
                                addKeywords(addKeywords: $addKeywords) {
                                  successMessage,
                                  data
                                  searchAdsErrors
                                }
                              }`


export const UPDATE_ADGROUP = gql`mutation($orgId: Float!, $campaignId: Float!, $adgroupId: Float!, $adgroupRequest:AdGroupUpdateReq!) {
                                updateAdGroup(orgId: $orgId, campaignId: $campaignId, adgroupId: $adgroupId, adgroupRequest: $adgroupRequest) {
                                    successMessage,
                                    data
                                    searchAdsErrors
                                  }
                                }`


export const TOGGLE_CAMPAIGNS = gql`mutation($orgId: Float!, $campaignId: Float! , $campaignStatus: CampaignStatusChange!) {
                                  enableDisableCampaign(orgId: $orgId, campaignId: $campaignId, campaignStatus: $campaignStatus) {
                                    successMessage,
                                    data
                                  }
                                }`

export const ADD_CREATIVE_SET = gql`mutation($orgId: Float!, $adgroupId: Float!, $campaignId: Float!, $creativeSet: CreativeSet!) {
                                addCreativeSet(orgId: $orgId, adgroupId: $adgroupId, campaignId: $campaignId, creativeSet: $creativeSet) {
                                  successMessage,
                                  data
                                  searchAdsErrors
                                }
                              }`


export const ADD_ADGROUP = gql`mutation($orgId: Float!, $adgroupReq: AdGroupReq!) {
                                addAdgroup(orgId: $orgId, adgroupReq: $adgroupReq) {
                                  successMessage,
                                  data
                                  searchAdsErrors
                                }
                              }`


export const ADD_NEGATIVE_KEYWORD_TO_ADGROUP = gql`mutation($addNegativeKeywords: AddNegativeKeywordsReqForAdgroup!){
                                                addNegativeKeywordsInAdGroup(addNegativeKeywords: $addNegativeKeywords){
                                                  successMessage,
                                                  data
                                                  searchAdsErrors
                                                }
                                              }`


export const ADD_NEGATIVE_KEYWORD_TO_CAMPAIGN = gql`mutation($addNegativeKeywords: AddNegativeKeywordsReqForCampaign!){
                                                  addNegativeKeywordsInCampaign(addNegativeKeywords: $addNegativeKeywords){
                                                  successMessage,
                                                  data
                                                  searchAdsErrors
                                                }
                                              }`


export const CHANGE_KEYWORD_BID_AMOUNT = gql`mutation($orgId: Float!, $campaignId: Float!, $adGroupId: Float!, $keywordBidAmount: KeywordBidAmountReq!){
                                          changeKeywordBidAmount(orgId: $orgId, campaignId: $campaignId, adGroupId: $adGroupId, keywordBidAmount: $keywordBidAmount){
                                            successMessage,
                                            data
                                            searchAdsErrors
                                          }
                                        }`

export const UPDATE_CREATIVE_SET = gql `mutation($orgId: Float!, $creativeSetId: Float!, $creativeSetUpdateReq: CreativeSetUpdateReq!){
                                      updateCreativeSet(orgId: $orgId, creativeSetId: $creativeSetId, creativeSetUpdateReq: $creativeSetUpdateReq){
                                        successMessage,
                                        data
                                        searchAdsErrors
                                      }
                                    }`;

export const UPDATE_PROFILE = gql`mutation($securityPrincipleID: String!, $updateProfileReq : UpdateProfileReq!){
                                    updateProfile(securityPrincipleID: $securityPrincipleID, updateProfileReq: $updateProfileReq){
                                            successMessage,
                                            data,
                                            code,
                                            content
                                          }
                                        }`


export const ADD_MEMBER = gql`mutation($memberDetails : MemberDetailReq!){
                                addTeamMember(memberDetails: $memberDetails){
                                                  successMessage,
                                                  data
                                                }
                                              }`


export const ReInvite_Member = gql`mutation($teamInviteReq : TeamInviteReq!){
                                  reInviteMember(teamInviteReq: $teamInviteReq){
                                    successMessage,
                                    data
                                  }
                               }`


export const CHANGE_MEMBER_STATUS = gql`mutation($changeMemberStatusReq : TeamInviteReq!){
                                      changeMemberStatus(changeMemberStatusReq: $changeMemberStatusReq){
                                        successMessage,
                                        data
                                      }
                                    }`


export const TARGET_KEYWORD_STATUS= gql`mutation($orgId: Float!, $changeTargetKeyStatusReq: ChangeTargetKeyStatusReq!) {
                                      changeTargetKeyStatus(orgId: $orgId, changeTargetKeyStatusReq: $changeTargetKeyStatusReq) {
                                        successMessage,
                                        data
                                      }
                                    }`


export const DELETE_CAMPAIGN = gql`mutation($orgId: Float!, $campaignId: Float!) {
                                      deleteCampaign(orgId: $orgId, campaignId: $campaignId) {
                                        successMessage
                                      }
                                    }`


export const CREATE_SEARCH_TAB_CAMPAIGN = gql`mutation($orgId: Float!, $campaignRequest: CampaignDraft!) {
                                            createSearchTabCampaign(orgId: $orgId, campaignRequest: $campaignRequest) {
                                              successMessage,
                                              data
                                              searchAdsErrors
                                            }
                                          }`

export const PROCESS_AUTH = gql`mutation($authReq: SAAuthCodeReq!) {
                                  processSAAuth(authReq: $authReq) {
                                    successMessage,
                                    data,
                                    content
                                  }
}`

export const DELETE_CAMPAIGN_NEG_KEY = gql`mutation($orgId: Float!, $campaignId: Float!, $keywordId: [Float!]!) {
  deleteCampaignNegKey(orgId: $orgId, campaignId: $campaignId, keywordId: $keywordId ) {
    successMessage
  }
}`

export const DELETE_ADGROUP_NEG_KEY = gql`mutation($orgId: Float!, $campaignId: Float!, $adgroupId: Float!, $keywordId: [Float!]!) {
  deleteAdgroupNegKey(orgId: $orgId, campaignId: $campaignId, adgroupId: $adgroupId, keywordId: $keywordId ) {
    successMessage
  }
}`

export const APP_SETTINGS_FOR_CAMPAIGN = gql`mutation($adamId: Float!, $valuesForAutoCampaign: [ValuesForAutoCampaign!]!) {
  appSettingsForCampaign(adamId: $adamId, valuesForAutoCampaign: $valuesForAutoCampaign) {
    successMessage
  }
}`
